const questions = [
  {
    id: "q1",
    description: "Tu te tiens devant un immense miroir, mais son reflet est inhabituel. Il ne te renvoie pas seulement ton apparence, mais l'écho de ton monde intérieur. Lorsque tu t'approches, la surface se trouble, puis s'éclaircit, dévoilant quatre chemins devant toi.",
    question: "Lequel t'attire instinctivement ?",
    options: [
      { text: "🌿 Un sentier paisible à travers une forêt lumineuse", value: "foret" },
      { text: "⛰️ Un chemin escarpé menant au sommet d'une montagne majestueuse", value: "montagne" },
      { text: "🌊 Une rivière sauvage dont le courant t'appelle à lâcher prise et à te laisser porter", value: "riviere" },
      { text: "🔲 Un labyrinthe mystérieux aux détours énigmatiques, te défiant d'en percer le secret", value: "labyrinthe" }
    ],
    next: "q2"
  },
  {
    id: "q2",
    question: "Ressens-tu souvent un écart entre la personne que tu es en privé et celle que tu montres en public ?",
    options: [
      { text: "🎭 J'ai l'impression de souvent jouer un rôle et de m'adapter.", value: "souvent" },
      { text: "😐 Parfois, cela dépend des situations, mais je ressens parfois ce décalage.", value: "parfois" },
      { text: "🕊️ Rarement, je me sens aligné·e avec qui je suis, quel que soit le contexte.", value: "rarement" }
    ],
    next: "q3"
  },
  {
    id: "q3",
    question: "Quelle est la peur qui influence le plus souvent tes choix et tes actions ?",
    options: [
      { text: "🚷 Le rejet", value: "rejet", next: "q4_rejet" },
      { text: "❌ L'échec", value: "echec", next: "q4_echec" },
      { text: "💔 L'abandon", value: "abandon", next: "q4_abandon" },
      { text: "😞 De ne pas être assez bien", value: "pas_assez_bien", next: "q4_pas_assez_bien" },
      { text: "🌪 Perdre le contrôle", value: "controle", next: "q4_controle" }
    ]
  },
  {
    id: "q4_rejet",
    question: "Te souviens-tu d'un moment marquant où tu t'es senti(e) rejeté(e) ?",
    type: "text",
    next: "q5"
  },
  {
    id: "q4_echec",
    question: "As-tu une expérience où un échec a marqué ton parcours ?",
    type: "text",
    next: "q5"
  },
  {
    id: "q4_abandon",
    question: "As-tu déjà ressenti une peur intense d'être laissé(e) de côté par quelqu'un d'important pour toi ?",
    type: "text",
    next: "q5"
  },
  {
    id: "q4_pas_assez_bien",
    question: "D'où penses-tu que la sensation de ne pas être assez vient ?",
    type: "text",
    next: "q5"
  },
  {
    id: "q4_controle",
    question: "Dans quelles situations ressens-tu le plus cette angoisse de manque de contrôle ?",
    type: "text",
    next: "q5"
  },
  {
    id: "q5",
    question: "As-tu l'impression de vivre pleinement ta propre vie ou celle que les autres attendent de toi ?",
    options: [
      { text: "🤯 J'essaye, mais j'ai encore du mal à m'affirmer.", value: "difficulte_affirmation" },
      { text: "😔 Plutôt celle que les autres attendent de moi, par peur de décevoir", value: "vie_attendue" },
      { text: "🎉 Je vis pleinement ma vie, je me sens libre dans mes choix.", value: "libre" },
      { text: "😵‍💫 Je n'en ai aucune idée, je me sens perdu(e).", value: "perdu" }
    ],
    next: "q6"
  },
  {
    id: "q6",
    question: "Repense à ton plus grand blocage dans ta vie. Si l'émotion que tu rencontres le plus souvent à travers lui était une personne qui frappait à ta porte… que ferais-tu en temps normal ?",
    options: [
      { text: "🚪 Je lui ouvre et je l'écoute, même si c'est inconfortable.", value: "ecoute" },
      { text: "😶 J'entrouvre le porte, mais je ne sais pas quoi lui dire ni comment réagir.", value: "difficulte" },
      { text: "🚫 Je claque la porte immédiatement, je refuse de la laisser entrer.", value: "resistance" },
      { text: "🙈 Je fais semblant de ne pas être là, en espérant qu'elle finisse par partir.", value: "nie" }
    ],
    next: "q7"
  },
  {
    id: "q7",
    question: "Si le regard des autres n'avait plus aucune influence sur toi, qu'oserais-tu faire différemment ?",
    type: "text",
    next: "q8"
  },
  {
    id: "q8", 
    question: "Qu'est-ce qui t'empêche aujourd'hui de devenir pleinement cette version de toi-même ?",
    options: [
      { text: "La peur du regard des autres.", value: "peur_regard" },
      { text: "Le manque de confiance en moi.", value: "manque_confiance" },
      { text: "Le doute sur ce que je veux vraiment.", value: "doute" },
      { text: "Une difficulté à passer à l'action.", value: "action_difficile" },
      { text: "Le sentiment d'être bloqué(e) par mon passé.", value: "bloque_passe" },
      { text: "Le besoin de tout contrôler et la peur de prendre des risques.", value: "besoin_controle" },
      { text: "Le confort de ma situation actuelle, même si elle ne me rend pas pleinement heureux(se).", value: "confort" },
      { text: "Autre chose: ", type:"text", value: "autre" }
    ],
    next: null // Dernière question, on termine ici
  }
];

export default questions;
