import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";

const Resultats = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const answers = location.state?.answers || {};

  const calculerAxes = (reponses) => {
    let axes = { authenticite: 0, confiance: 0, lacher_prise: 0, ancrage: 0, depassement: 0 };
  
    const valeurs = {
  // 🔹 Choix symbolique du chemin (Q1)
  foret: { authenticite: 1, confiance: 1, lacher_prise: 2, ancrage: 2, depassement: 0 },
  montagne: { authenticite: 1, confiance: 2, lacher_prise: -1, ancrage: 2, depassement: 3 },
  riviere: { authenticite: 2, confiance: 1, lacher_prise: 1, ancrage: 0, depassement: 0 },
  labyrinthe: { authenticite: -1, confiance: 1, lacher_prise: -2, ancrage: 2, depassement: 2 },

  // 🔹 Écart entre soi privé/public (Q2)
  souvent: { authenticite: -2, confiance: -1, lacher_prise: -1, ancrage: -1, depassement: 0 },
  parfois: { authenticite: -1, confiance: 0, lacher_prise: 0, ancrage: 0, depassement: 1 },
  rarement: { authenticite: 2, confiance: 1, lacher_prise: 1, ancrage: 1, depassement: 1 },

  // 🔹 Peur dominante (Q3)
  rejet: { authenticite: -3, confiance: -3, lacher_prise: -1, ancrage: -2, depassement: -1 },
  echec: { authenticite: -1, confiance: -3, lacher_prise: -2, ancrage: -1, depassement: -1 },
  abandon: { authenticite: -2, confiance: -2, lacher_prise: 0, ancrage: -2, depassement: 0 },
  pas_assez_bien: { authenticite: -3, confiance: -3, lacher_prise: -2, ancrage: -2, depassement: -1 },
  controle: { authenticite: -2, confiance: -1, lacher_prise: -2, ancrage: 1, depassement: 0 },

  // 🔹 Sentiment de vivre sa propre vie (Q5)
  difficulte_affirmation: { authenticite: -1, confiance: -2, lacher_prise: 0, ancrage: 0, depassement: 1 },
  vie_attendue: { authenticite: -3, confiance: -3, lacher_prise: -2, ancrage: -2, depassement: -1 },
  libre: { authenticite: 2, confiance: 2, lacher_prise: 2, ancrage: 2, depassement: 2 },
  perdu: { authenticite: -2, confiance: -3, lacher_prise: -1, ancrage: -2, depassement: -1 },
    
  // 🔹 Accueil de l'émotion (Q6)
  ecoute: { authenticite: 2, confiance: 3, lacher_prise: 3, ancrage: 2, depassement: 2 },
  difficulte: { authenticite: 1, confiance: 1, lacher_prise: 0, ancrage: 1, depassement: 0 },
  resistance: { authenticite: -3, confiance: -2, lacher_prise: -3, ancrage: -1, depassement: -3 },
  nie: { authenticite: -2, confiance: -3, lacher_prise: -2, ancrage: -2, depassement: -2 },

  // 🔹 Blocage pour devenir soi-même (Q8)
  peur_regard: { authenticite: -2, confiance: -2, lacher_prise: -2, ancrage: -1, depassement: 0 },
  manque_confiance: { authenticite: -1, confiance: -3, lacher_prise: -2, ancrage: -1, depassement: 0 },
  doute: { authenticite: -2, confiance: -1, lacher_prise: -1, ancrage: -2, depassement: -1 },
  action_difficile: { authenticite: -1, confiance: -2, lacher_prise: -2, ancrage: -1, depassement: -3 },
  bloque_passe: { authenticite: -1, confiance: -2, lacher_prise: -2, ancrage: -2, depassement: -1 },
  besoin_controle: { authenticite: -2, confiance: -1, lacher_prise: -3, ancrage: -1, depassement: -1 },
  confort: { authenticite: -2, confiance: -3, lacher_prise: 0, ancrage: 0, depassement: -2 }
    };    
  
    reponses.forEach(rep => {
      if (valeurs[rep]) {
        Object.keys(valeurs[rep]).forEach(axis => {
          axes[axis] += valeurs[rep][axis];
        });
      }
    });
  
    return axes;
  };

  const genererResume = () => {
    if (!answers.q1) {
      return <p className="error-text">Nous n'avons pas pu analyser tes réponses. Veux-tu recommencer ?</p>;
    }
  
    let summary = [];
    const scores = calculerAxes(Object.values(answers));
    console.log("Scores calculés :", scores);
    summary.push(<h2>🌟 Ton profil révèle que :</h2>);
  
    // 🌟 Authenticité
    if (scores.authenticite <= -10) {
      summary.push(<p>😶 Tu ressens une forte difficulté à être toi-même. Peut-être que tu te caches derrière un masque pour t'adapter aux attentes des autres, au point de ne plus savoir ce qui est authentique pour toi.</p>);
    } else if (scores.authenticite <= -5) {
      summary.push(<p>🤔 Tu ressens parfois un décalage entre ce que tu es au fond et ce que tu montres aux autres. Il se peut que la peur du rejet ou du jugement influence ton comportement.</p>);
    } else if (scores.authenticite <= 0) {
      summary.push(<p>🙂 Tu es globalement en accord avec toi-même, mais il t'arrive de douter ou d'adapter ton comportement en fonction du contexte.</p>);
    } else {
      summary.push(<p>🌱 Tu es profondément aligné·e avec qui tu es. Ton authenticité brille et tu oses t'affirmer sans crainte du regard des autres.</p>);
    }

    // 🌟 Confiance
    if (scores.confiance <= -10) {
      summary.push(<p>😟 Ton manque de confiance t'empêche d'oser et de croire en tes capacités. Tu peux ressentir une peur intense de l'échec ou du regard des autres.</p>);
    } else if (scores.confiance <= -5) {
      summary.push(<p>😐 Tu manques parfois de confiance en toi, ce qui peut te freiner dans certaines décisions ou prises d'initiative.</p>);
    } else if (scores.confiance >= 3) {
      summary.push(<p>🔥 Tu fais preuve d'une grande confiance en toi ! Tu crois en tes capacités et tu avances avec assurance dans tes choix.</p>);
    }

    // 🌟 Lâcher-prise
    if (scores.lacher_prise <= -10) {
      summary.push(<p>⚠️ Tu as un grand besoin de contrôle et tu ressens une difficulté à laisser aller les choses. Cela peut te causer du stress et t'empêcher de profiter pleinement du moment présent.</p>);
    } else if (scores.lacher_prise <= -5) {
      summary.push(<p>😓 Tu aimerais lâcher prise davantage, mais certaines peurs ou incertitudes t'empêchent parfois de le faire totalement.</p>);
    } else if (scores.lacher_prise <= 2) {
      summary.push(<p>🙂 Tu arrives à lâcher prise dans certaines situations, même si tu ressens parfois le besoin de tout maîtriser.</p>);
    } else {
      summary.push(<p>🌊 Tu es dans un état de lâcher-prise qui te permet d'accueillir la vie avec fluidité et confiance.</p>);
    }

    // 🌟 Ancrage
    if (scores.ancrage <= -7) {
      summary.push(<p>🌪️ Tu te sens souvent perdu·e ou déconnecté·e de toi-même. Peut-être ressens-tu un manque de stabilité ou de repères.</p>);
    } else if (scores.ancrage <= -1) {
      summary.push(<p>🤷‍♂️ Tu ressens parfois un manque d'ancrage, ce qui peut te donner une impression d'incertitude ou de flottement dans ta vie.</p>);
    } else if (scores.ancrage >= 5) {
      summary.push(<p>🌳 Tu es solidement ancré·e dans ta réalité. Tu sais où tu en es et avances avec sérénité.</p>);
    }

    // 🌟 Dépassement
    if (scores.depassement <= -7) {
      summary.push(<p>🛑 Tu as tendance à éviter les défis et à rester dans ta zone de confort. La peur de l'échec ou du rejet peut te freiner.</p>);
    } else if (scores.depassement <= -3) {
      summary.push(<p>🤔 Tu hésites parfois à sortir de ta zone de confort, mais tu fais de petits pas vers le changement.</p>);
    } else if (scores.depassement >= 3) {
      summary.push(<p>🚀 Tu es une personne assez audacieuse qui n'hésite pas à relever des défis et à sortir de sa zone de confort pour évoluer.</p>);
    }

    summary.push(<hr />); // Ligne de séparation visuelle
  
    // 🔹 **Chemin choisi (Q1)**
    const pathDescriptions = {
      foret: "🌿 Tu recherches l'harmonie et un espace où être toi-même sans pression, guidé(e) par une sensibilité intérieure.",
      montagne: "⛰️ Tu es en quête de dépassement et d'évolution, prêt(e) à affronter les défis pour atteindre tes objectifs.",
      riviere: "🌊 Tu ressens un profond besoin de liberté et de spontanéité, préférant te laisser porter par le flux de la vie.",
      labyrinthe: "🔲 Tu es en pleine réflexion sur toi-même, cherchant encore les réponses qui donneront du sens à ton chemin."
    };
    if (answers.q1) summary.push(<p><strong>🛤️ Ton chemin instinctif :</strong> {pathDescriptions[answers.q1]}</p>);

    // 🔹 Authenticité vs. masque social (Q2) 
    if (answers.q2) {
      let authenticityDescription = "";
      if (answers.q2 === "souvent") {
        authenticityDescription = "🎭 Tu ressens un écart important entre qui tu es vraiment et ce que tu montres au monde.";
        if (scores.authenticite <= -6) {
          authenticityDescription += " Cet écart semble peser lourd, comme si tu te perdais dans les attentes des autres.";
        } else if (scores.authenticite > -6 && scores.authenticite <= 0) {
          authenticityDescription += " Mais au fond, une part de toi cherche à se libérer de ce masque.";
        }
        if (scores.confiance <= -5) {
          authenticityDescription += " Ton manque de confiance renforce cette habitude de te cacher derrière un rôle.";
        } else if (scores.confiance >= 3) {
          authenticityDescription += " Ta confiance grandissante pourrait t'aider à lever ce masque.";
        }
        if (scores.lacher_prise <= -5) {
          authenticityDescription += " Ton besoin de contrôle te pousse à maintenir cette façade, par peur de lâcher prise.";
        }
      } else if (answers.q2 === "parfois") {
        authenticityDescription = "😐 Tu essaies d'être toi-même, mais certaines situations te poussent encore à t'adapter aux attentes extérieures.";
        if (scores.authenticite <= -6) {
          authenticityDescription += " Cela te pèse parfois, comme si tu avais du mal à te retrouver pleinement.";
        } else if (scores.authenticite > 0) {
          authenticityDescription += " Cependant, ton authenticité naturelle commence à prendre le dessus dans bien des cas.";
        }
        if (scores.ancrage <= -1) {
          authenticityDescription += " Ce flottement vient peut-être d'un manque de repères clairs dans ta vie.";
        } else if (scores.ancrage >= 5) {
          authenticityDescription += " Mais ton ancrage solide te permet de rester fidèle à toi-même, même sous pression.";
        }
        if (scores.depassement >= 3) {
          authenticityDescription += " Ton audace te pousse à briser ces adaptations pour affirmer qui tu es vraiment.";
        }
      } else if (answers.q2 === "rarement") {
        authenticityDescription = "🕊️ Tu es généralement aligné·e avec toi-même et tu ne ressens que peu de pression à jouer un rôle.";
        if (scores.authenticite >= 3) {
          authenticityDescription += " Cette authenticité est une force qui te guide avec clarté et sérénité.";
        } else if (scores.authenticite <= 0) {
          authenticityDescription += " Mais parfois, un léger doute peut encore te faire hésiter sur ton propre chemin.";
        }
        if (scores.confiance >= 3) {
          authenticityDescription += " Ta confiance en toi renforce cette capacité à rester vrai·e en toutes circonstances.";
        }
        if (scores.lacher_prise >= 3) {
          authenticityDescription += " Ton aisance à lâcher prise te libère des attentes extérieures.";
        } else if (scores.lacher_prise <= -5) {
          authenticityDescription += " Pourtant, un besoin de contrôle peut encore te retenir dans certains contextes.";
        }
      }
      summary.push(<p>{authenticityDescription}</p>);
    }

    // 🔹 **Peur dominante (Q3)**
    const fearDescriptions = {
      rejet: "🚷 La peur du rejet influence tes choix et te pousse parfois à t'adapter aux autres pour éviter la solitude.",
      echec: "❌ L'échec est une crainte forte chez toi, ce qui peut t'amener à hésiter avant d'agir.",
      abandon: "💔 Tu crains d'être laissé(e) de côté, ce qui te rend très attentif(ve) aux relations et à leur équilibre.",
      pas_assez_bien: "😞 Tu ressens souvent que tu n'es pas à la hauteur, ce qui peut te freiner dans ton épanouissement.",
      controle: "🌪️ Tu as besoin de garder la maîtrise des choses, car perdre le contrôle t'angoisse profondément."
    };
    if (answers.q3) summary.push(<p><strong>😱 Peur dominante :</strong> {fearDescriptions[answers.q3]}</p>);

    // 🔹 **Gestion des émotions (Q6)**
    const emotionResponses = {
      ecoute: "🚪 Tu accueilles tes émotions et cherches à les comprendre, même si cela peut être difficile.",
      difficulte: "😶 Tu ressens tes émotions mais ne sais pas toujours comment y répondre, ce qui peut créer de l'incertitude.",
      resistance: "🚫 Tu refuses d'écouter certaines émotions, ce qui peut te créer des blocages inconscients.",
      nie: "🙈 Tu as tendance à éviter certaines émotions inconfortables, espérant qu'elles disparaissent"
    };
    if (answers.q6) summary.push(<p><strong>💙 Relation aux émotions :</strong> {emotionResponses[answers.q6]}</p>);

    // 🔹 **Réponses libres (Q4 et Q7)**
    if (answers.q4_rejet) {
      summary.push(<p>📝 <strong>Un souvenir marquant :</strong> "{answers.q4_rejet}". Ce moment semble marquant et a peut-être influencé tes choix.</p>);
    }
    if (answers.q7) {
      summary.push(<p>🚀 <strong>Tu aimerais oser :</strong> "{answers.q7}", mais quelque chose semble encore te retenir. Explorer ce frein pourrait t'aider à avancer vers cette aspiration.</p>);
    }

    // 🔹 **Blocage principal (Q8)**
    const blockages = {
      peur_regard: "👀 Tu accordes beaucoup d'importance au regard des autres, ce qui peut te limiter dans tes choix.",
      manque_confiance: "💭 Le manque de confiance en toi freine ton épanouissement, mais ce n'est pas une fatalité !",
      doute: "❓ Tu es en pleine remise en question et ne sais pas encore quelle direction prendre.",
      action_difficile: "🚦 Tu ressens une difficulté à passer à l'action, même si l'envie est là.",
      bloque_passe: "⏳ Ton passé semble encore peser sur ton présent, ce qui peut créer des schémas récurrents.",
      besoin_controle: "🎛️ Tu ressens le besoin de tout contrôler, ce qui peut parfois te freiner dans ton lâcher-prise.",
      confort: "🛋️ Tu es dans une zone de confort qui ne te satisfait pas pleinement, mais le changement te fait hésiter."
    };
    if (answers.q8) {
      const descriptionBlocage = blockages[answers.q8] || `💬 "${answers.q8}"`;
      summary.push(<p><strong>🚧 Ce qui te bloque le plus :</strong> {descriptionBlocage}</p>);
    }

    // 🔹 **Message de conclusion**
    summary.push(<hr />);
    summary.push(<em> <strong>Comprendre ce qui te freine</strong> est déjà un premier pas vers plus de liberté et d'authenticité. Continue ton chemin à ton rythme. </em>
    );

    return <div className="result-text">{summary}</div>;
  };  

  return (
    <div className="result-page">
      <h1>Résultat de ton alignement</h1>
      {genererResume()}

      <button onClick={() => navigate("/cours")} className="btn-green">Voir les cours 📚</button>
      <button onClick={() => navigate("/intro")} className="btn-blue">Recommencer</button>
    </div>
  );
};

export default Resultats;
