import React, { useEffect, useState } from 'react';
import { logEvent, setConsent } from 'firebase/analytics';
import { analytics } from './donnees/firebase.js';

// Composant CookieBanner
const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBanner(true);
    } else {
      const parsedConsent = JSON.parse(consent);
      applyConsent(parsedConsent);
    }
    // Désactiver Analytics par défaut
    setConsent({
      analytics_storage: 'denied',
    });
  }, []);

  useEffect(() => {
    const body = document.body;
    if (showBanner) {
      body.classList.add('has-cookie-banner');
    } else {
      body.classList.remove('has-cookie-banner');
    }
  }, [showBanner]);

  const applyConsent = (consent) => {
    setConsent({
      analytics_storage: consent.analytics ? 'granted' : 'denied',
    });
    if (consent.analytics) {
      logEvent(analytics, 'consent_granted', { timestamp: new Date().toISOString() });
    }
  };

  const handleAcceptAll = () => {
    const consent = { analytics: true };
    localStorage.setItem('cookieConsent', JSON.stringify(consent));
    applyConsent(consent);
    setShowBanner(false);
  };

  const handleRefuseAll = () => {
    const consent = { analytics: false };
    localStorage.setItem('cookieConsent', JSON.stringify(consent));
    applyConsent(consent);
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-banner">
      <p className="cookie-text">
        Nous affinons votre expérience avec des cookies. Les acceptez-vous ?
      </p>
      <div className="cookie-buttons">
        <button onClick={handleAcceptAll} className="cookie-button cookie-button-accept">
          Accepter
        </button>
        <button onClick={handleRefuseAll} className="cookie-button cookie-button-refuse">
          Refuser
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;