import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ScrollToTopButton = () => {
    const seuil = 1200;
    const [isVisible, setIsVisible] = useState(false);
    const [isScrollBeyondThreshold, setIsScrollBeyondThreshold] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const refreshPage = () => {
        navigate(location.pathname);
    };

    useEffect(() => {
        const checkScrollThreshold = () => {
            if (window.scrollY > seuil) {
                setIsScrollBeyondThreshold(true);
            } else {
                setIsScrollBeyondThreshold(false);
                setIsVisible(false);
            }
        };

        const mouseMoveHandler = (event) => {
            const isCursorNearTop = event.clientY <= 130;
            const isCursorNearLeft = event.clientX <= 100;
            const isCursorNearButton = isCursorNearTop || isCursorNearLeft;

            setIsVisible(isScrollBeyondThreshold && isCursorNearButton);
        };

        window.addEventListener('scroll', checkScrollThreshold);
        window.addEventListener('mousemove', mouseMoveHandler);

        return () => {
            window.removeEventListener('scroll', checkScrollThreshold);
            window.removeEventListener('mousemove', mouseMoveHandler);
        };
    }, [isScrollBeyondThreshold]);

    return (
        <button
            onClick={refreshPage}
            className={`scroll-to-top-button ${isVisible ? 'visible' : ''}`}
        >
            <img src="../pictures/fleche.png" alt="↑ Haut" />
        </button>
        );
};

export default ScrollToTopButton;
