import React from 'react';
import './article-page.css';

const ArticlePage1 = () => {
  const article = {
    title: "Les manipulations sur nos sites, applis et jeux de tous les jours pour vendre notre attention",
    author: "Aurélien Bessonnet",
    date: "13 février 2025",
    content: [
      { type: 'paragraph', text: "Vous ouvrez <span class='bold'>un réseau social</span>, puis regardez une vidéo et, sans vous en rendre compte, une heure s'est écoulée. On peut se demander quelle est cette <span class='highlight-gold'>magie noire</span>, même si aujourd'hui, ce n'est plus si caché que ça." },
      { type: 'paragraph', text: "Les plateformes comme <span class='highlight-orange'>Instagram</span> ou <span class='highlight-orange'>TikTok</span> sont conçues selon des principes psychologiques pour capter et retenir <span class='bold'>l'attention</span> des utilisateurs. Leur succès repose sur la combinaison du contenu interactif, de formats optimisés et d'algorithmes prédictifs, qui exploitent les mécanismes de notre cerveau pour créer une forme de <span class='highlight-purple'>dépendance comportementale</span>." },
      { type: 'paragraph', text: "<span class='italic'>Je vous décortique un peu comment tout ça fonctionne :</span>" },
      { type: 'paragraph', text: "Le geste mécanique de <span class='highlight-gold'>scroller</span> (faire défiler) crée premièrement une <span class='highlight-purple'>illusion d'abondance sans effort</span> : l'absence de friction (pas de bouton 'suivant', pas de pause obligatoire) retire ces moments où on peut sentir naturellement pouvoir sortir de l'application." },
      { type: 'paragraph', text: "Deuxième point, les vidéos sont <span class='highlight-orange'>ultra-concises</span> et elles s'enchaînent rapidement, ce qui nous laisse absorber une grande quantité de contenus en peu de temps." },
      { type: 'paragraph', text: "<span class='souligne'>Musiques entraînantes, effets visuels, transitions dynamiques…</span> Chaque élément est pensé pour activer les circuits de la récompense (<span class='highlight-purple'>dopamine</span>) et maintenir l'éveil sensoriel." },
      { type: 'paragraph', text: "Résultat : Le cerveau traite une grande quantité d'informations dans ce flux, dépassant rapidement sa capacité de traitement conscient." },
      { type: 'paragraph', text: "Le basculement vers un état plus passif et une habitude à cela est une transition qui ne se remarque pas vraiment." },
      { type: 'paragraph', text: "Au début d'une session, un utilisateur est pleinement conscient (enfin normalement ..😅) : il choisit activement des vidéos, juge le contenu, clique sur des profils. Mais à mesure qu'il scrolle, deux phénomènes se produisent :" },
      { type: 'paragraph', text: "Le <span class='highlight-gold'>cortex préfrontal</span> (siège de la prise de décision consciente) est submergé par le flux de contenu, <span class='italic bold'>saturant progressivement</span>, limitant notre aptitude à comprendre et retenir les informations." },
      { type: 'paragraph', text: "Ce phénomène ne touche pas seulement notre attention, mais également notre <span class='bold'>système nerveux</span>. Des recherches indiquent que le défilement continu sur les réseaux sociaux active en permanence notre <span class='highlight-orange'>système nerveux sympathique</span> (associé à la réponse de stress « combat ou fuite ») tout en diminuant <span class='italic'>le tonus vagal</span>, qui joue un rôle clé dans la détente et la régulation émotionnelle. À long terme, cette stimulation excessive favorise un état de <span class='highlight-purple'>stress chronique</span>, altère notre capacité à traiter efficacement les informations.¹" },
      { type: 'paragraph', text: "À force, par <span class='highlight-gold'>la surcharge cognitive</span>, le cerveau réduit l'engagement conscient et laisse place à des mécanismes plus automatiques. On ne 'choisit' plus vraiment. On répond plus à des stimuli sensoriels (mouvements, sons, émotions primaires), guidé par des réflexes conditionnés et le système de récompense dopaminergique. Chaque interaction gratifiante (vidéo engageante, like, notification) renforce ce cycle de réactions, tant que l'on n'est plus assez engagé et conscient dans notre attitude—ce qui devient physiquement éprouvant pour le cerveau." },
      { type: 'paragraph', text: "La théorie de la charge cognitive de Sweller explique qu'un excès d'informations nuit à l'apprentissage et à la mémorisation. Une surcharge prolongée empêche le cerveau de trier et retenir efficacement les informations pertinentes, ce qui pourrait expliquer la sensation de <span class='highlight-purple'>brouillard mental</span> ressentie après de longues sessions sur une appli sociale." },
      { type: 'paragraph', text: "Les algorithmes de recommandation jouent un rôle clé dans cette transition :" },
      { type: 'paragraph', text: "Les contenus gratifiants (humour, beauté, validation sociale) sont proposés de façon aléatoire, ce qui active le système dopaminergique et renforce l'habitude de scroller." },
      { type: 'paragraph', text: "Plus l'algorithme <span class='souligne'>apprend des préférences de l'utilisateur</span> (via les likes, le temps de regard...), plus il propose des contenus hyper-ciblés, créant une illusion de pertinence qui rend difficile l'arrêt. <span class='italic'>Il n'empêche qu'on peut suivre des comptes super intéressants</span>, des personnes inspirantes. Malgré tout, l'algorithme continuera toujours d'essayer de nous proposer des contenus ici et là, des choses qui <span class='bold'>fonctionnent</span> pour le réseau social et qui nous pousse à rester sur le site ou l'application." },
      { type: 'paragraph', text: "<span class='italic'>Les plateformes numériques, notamment les réseaux sociaux, sont en concurrence pour capter, conserver et monétiser notre attention.</span>²" },
      { type: 'paragraph', text: "Les conséquences générales d'une utilisation régulière : <span class='highlight-purple'>Déconnexion</span> et <span class='highlight-purple'>Dépendance</span>." },
      { type: 'paragraph', text: "Une étude d'ExpressVPN³ rappelle les enjeux relatifs aux effets de la dépendance technologique sur la <span class='italic bold'>santé mentale</span>, notamment l'isolement social, la dépression, le stress et l'anxiété, ainsi que le manque de sommeil de qualité." },
      { type: 'paragraph', text: "Tout dépend peut-être de notre utilisation, mais en tout cas, il faut bien être conscient que le système est conçu expressément pour nous faire rester dessus." },
      { type: 'paragraph', text: "Un peu comme les <span class='highlight-orange'>gacha games</span>, jeux où le joueur est le produit, dans le sens où le jeu est gratuit, mais que tout sera fait pour que le joueur soit conditionné pour s'attacher à l'univers du jeu et percevoir les avantages payant comme acceptables." },
      { type: 'paragraph', text: "En résumé, les réseaux sociaux exploitent les limites de notre attention consciente pour nous maintenir dans un état de consommation passive, où la frontière entre choix délibéré et réflexe inconscient s'estompe. Cette ingénierie, qui exploite des mécanismes étudiés en addictologie, bien que lucrative pour les plateformes, ne nous rend pas service. Un réseau social est un moyen extraordinaire pour échanger des informations, mais cela peut se révéler être un véritable enfer dans ce cas où l'outil sert un modèle basé sur le <span class='highlight-gold'>surprofit</span>." },
    ],
    sources: [
      { text: "¹Comment les réseaux sociaux influencent le système nerveux", url: "https://neurofit.app/learn/fr/social_media_nervous_system/" },
      { text: "²La captation de l'attention par les plateformes numériques", url: "https://www.clemi.fr/ressources/ressources-pedagogiques/la-captation-de-lattention-par-les-plateformes-numeriques" },
      { text: "³Technologie et santé mentale : trouver un équilibre", url: "https://www.expressvpn.com/fr/blog/digital-minimalism-generational-insights/" },
    ],
  };

  return (
    <div className="article-container">
      <header className="article-header">
        <h1 className="article-title">{article.title}</h1>
        <div className="article-metadata">
          Par {article.author} · {article.date}
        </div>
      </header>

      <article className="article-content">
        {article.content.map((section, index) => (
          <p 
            key={index} 
            className="article-paragraph"
            dangerouslySetInnerHTML={{ __html: section.text }}
          />
        ))}
      </article>

      <div className="source-links">
        <h3>Sources :</h3>
        {article.sources.map((source, index) => (
          <div key={index} className="source-link">
            <a href={source.url} target="_blank" rel="noopener noreferrer">
              {source.text}
            </a>
          </div>
        ))}
      </div>

      <footer className="original-link">
        <a href="https://flowgraines.fr" target="_blank" rel="noopener noreferrer">
          Retour sur flowgraines.fr
        </a>
      </footer>
    </div>
  );
};

export default ArticlePage1;