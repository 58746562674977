import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './style.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext.js';
import { logEvent } from "firebase/analytics";
import { analytics } from './donnees/firebase.js';
import { validDiscounts } from './metaprogrammes.js';

const CheckoutForm = ({ onSuccessfulCheckout, programme, onClose }) => {
  const { currentUser, userData, loadUserData } = useAuth();

  const stripe = useStripe();
  const elements = useElements();

  const [paymentStatus, setPaymentStatus] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);  
  const [price, setPrice] = useState(programme?.price ? `${programme.price} € TTC` : "0 € TTC");
  const [isConnectedMessage, setIsConnectedMessage] = useState('');

  const navigate = useNavigate();

  const [discountCode, setDiscountCode] = useState('');
  const [appliedDiscount, setAppliedDiscount] = useState(null);
  const [discountError, setDiscountError] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [isCardComplete, setIsCardComplete] = useState(false);

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#32325d',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
      },
    },
  };

  useEffect(() => {
    if (currentUser && (!userData || !userData.email)) {
      loadUserData(currentUser.uid).catch(error => {
        console.error("Erreur lors du chargement des données utilisateur", error);
      });
    }
  }, [currentUser, userData, loadUserData]);

  const extractPrice = (priceString) => {
    const match = priceString.match(/\d+(\.\d+)?/); // Cherche un nombre (entier ou décimal)
    return match ? parseFloat(match[0]) : 0; // Retourne 0 si aucun nombre trouvé
  };

  const handleApplyDiscount = () => {
    if (!discountCode.trim()) {
      setDiscountError('Veuillez entrer un code promo');
      return;
    }
  
    const code = discountCode.toUpperCase();
    const discountConfig = validDiscounts[code];
  
    // Vérification de l'existence du code
    if (!discountConfig) {
      setDiscountError('Code non reconnu');
      redo();
      return;
    }

    const discountValue = paymentType === 'getCourse' 
    ? discountConfig.course 
    : discountConfig.subscription;

    if (!discountValue) {
      setDiscountError('Code non valable pour ce type de paiement');
      return;
    }

    setAppliedDiscount(discountValue); // Stocker la valeur numérique de la réduction
    setDiscountError('');

    // Calcul dynamique selon la configuration
    const basePrice = paymentType === 'getCourse' ? programme.price : 19;
    const newPrice = basePrice * (1 - discountValue / 100);
    
    setPrice(paymentType === 'getCourse' 
      ? `${newPrice.toFixed(2)} € TTC`
      : `${newPrice.toFixed(2)} €/mois TTC`);
  };

   // Mise à jour des gestionnaires de type de paiement
   const handlePaymentTypeChange = (type) => {
    setPaymentType(type);
    setDiscountCode('');
    setAppliedDiscount(null);
    
    // Calcul du prix en fonction du type de paiement
    const newPrice = type === 'getCourse' ? programme.price : 19;

    // Mise à jour de l'état price
    if (type === 'getCourse') {
      setPrice(`${programme.price} € TTC`);
    } else {
      setPrice('19 euros par mois TTC');
    }

    // Enregistrement de l'événement Analytics avec le prix numérique
    logEvent(analytics, "select_payment_type", {
      payment_type: type,
      programme_id: programme.id,
      programme_name: programme.title || "Nom non défini",
      price: newPrice,
      currency: "EUR",
      timestamp: new Date().toISOString(),
    });
  };

  const redo = () => {
    setAppliedDiscount(null);
    if (paymentType === 'getCourse') {
      setPrice(`${programme.price} € TTC`);
    } else {
      setPrice('19 euros par mois TTC');
    }
  };

  const API_URLS = {
    getCourse: process.env.REACT_APP_FIREBASE_FUNCTION_API_URL,
    subscription: process.env.REACT_APP_FIREBASE_FUNCTION_APISUB_URL,
  };

  const messageSuccessCourse = "Paiement réussi. Votre programme est disponible.";
  const messageSuccessSub = "Paiement réussi. Vous êtes maintenant abonné(e).";

  const handleSuccess = (paymentIntent, paymentType) => {
    const clientSecret = paymentIntent.clientSecret;
    if (clientSecret) {
      logEvent(analytics, "purchase", {
        transaction_id: paymentIntent.id, // Identifiant unique de la transaction
        payment_type: paymentType, // "getCourse" ou "subscription"
        programme_id: programme.id,
        programme_name: programme.title || "Nom non défini",
        value: extractPrice(price), // Prix final (après réduction, si applicable)
        currency: "EUR",
        discountCode: appliedDiscount ? discountCode : null,
        timestamp: new Date().toISOString(),
      });

      setPaymentStatus(
        paymentType === 'getCourse' ? messageSuccessCourse : messageSuccessSub
      );
      navigate(`/verification?payment_intent_client_secret=${clientSecret}`);
    } else {
      console.error('Client Secret manquant.');
      setPaymentStatus('Une erreur est survenue. Veuillez réessayer.');
    }
    onSuccessfulCheckout();
  };

  // Gérer les appels API
  const fetchAPI = async (url, body) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      let errorMsg = `Erreur lors de l'appel API`;
      try {
        const errorBody = await response.json();
        errorMsg = errorBody.message || errorMsg;
        console.error("Erreur API :", errorBody);
      } catch {
        errorMsg += `. Impossible de parser la réponse d'erreur.`;
      }
      throw new Error(errorMsg);
    }

    return response.json();
  };

  // Fonction principale : handleSubmit
  const handleSubmit = async (event) => {
    event.preventDefault();

    logEvent(analytics, "click_pay_button", {
      payment_type: paymentType,
      programme_id: programme.id,
      programme_name: programme.title || "Nom non défini",
      price: extractPrice(price) || (paymentType === 'getCourse' ? programme.price : 19),
      discountCode: appliedDiscount ? discountCode : null,
      currency: "EUR",
      timestamp: new Date().toISOString(),
    });

    if (!acceptedTerms) {
      setPaymentStatus("Veuillez accepter les conditions pour continuer.");
      return;
    }

    if (!currentUser || !userData || !userData.email || !userData.emailVerified) {
      setIsConnectedMessage("Veuillez vous reconnecter ou faire revérifier votre mail.");
      return;
    }

    if (!paymentType) {
      setPaymentStatus("Veuillez sélectionner un type de paiement.");
      return;
    }

    if (!isCardComplete) {
      setPaymentStatus("Veuillez remplir les informations de votre carte.");
      setIsSubmitting(false);
      return;
    }

    if (!stripe || !elements) {
      setPaymentStatus("Le système de paiement n'est pas disponible pour le moment. Veuillez rafraîchir la page ou réessayer plus tard.");
      return;
    }

    setIsSubmitting(true);
    setPaymentStatus('');

    try {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        throw new Error("Le champ de carte est introuvable. Veuillez réessayer.");
      }

      const { error, paymentMethod: createdPaymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        throw new Error(`${error.message}`);
      }

      let apiUrl;
      let requestBody;

      if (paymentType === 'getCourse') {
        apiUrl = API_URLS[paymentType];
        requestBody = {
          paymentMethodId: createdPaymentMethod.id,
          programmeId: programme.id,
          userId: currentUser.uid,
          email: userData.email,
          discountCode: appliedDiscount ? discountCode : null,
          discountPercentage: appliedDiscount || 0
        };
      } else if (paymentType === 'subscription') {
        apiUrl = API_URLS[paymentType];
        requestBody = {
          paymentMethodId: createdPaymentMethod.id,
          userId: currentUser.uid,
          email: userData.email,
          discountCode: appliedDiscount ? discountCode : null,
          discountPercentage: appliedDiscount || 0
        };
      } else {
        throw new Error("Méthode de paiement invalide.");
      }

      // Appel API pour le paiement ou l'abonnement
      const apiResult = await fetchAPI(apiUrl, requestBody);

      if (!apiResult || !apiResult.clientSecret) {
        throw new Error("La réponse de l'API est invalide ou incomplète.");
      }

      // Vérifier si le PaymentIntent est déjà confirmé
      if (apiResult.status === 'succeeded') {
        handleSuccess(apiResult, paymentType);
        return;
      }

      if (apiResult.status === 'requires_action') {
        const { paymentIntent, error: stripeError } = await stripe.confirmCardPayment(apiResult.clientSecret);
    
        if (stripeError) {
          throw new Error(`Erreur de confirmation Stripe : ${stripeError.message}`);
        }
    
        if (paymentIntent.status === 'succeeded') {
          handleSuccess(paymentIntent, paymentType);
          return;
        } else {
          throw new Error("Le paiement n'a pas pu être finalisé après l'authentification.");
        }
      }

      if (apiResult.status === 'requires_payment_method') {
        setPaymentStatus("Le paiement a échoué. Veuillez vérifier votre carte.");
        return;
      }
      
      if (apiResult.status === 'incomplete') {
        setPaymentStatus("Votre abonnement est en attente. Veuillez contacter le support.");
        return;
      }
      // Si le statut est inattendu
      throw new Error("Statut inattendu du paiement. Veuillez réessayer.");
    } catch (error) {
      const errorMessage = error.message || "Une erreur inconnue est survenue.";
      setPaymentStatus(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="overlay-checkout"></div>
      <div className="form-checkout">
        <button onClick={onClose} className="close-button">
          <img src="/pictures/close_icon.png" alt="Fermer" />
        </button>
        <form onSubmit={handleSubmit}>
          {isConnectedMessage && <p>{isConnectedMessage}</p>}
          <p className='title'>{programme.title}</p>
          {programme.subtitle && (
            <p className="subtitle">{programme.subtitle}</p>
          )}
          <p>
          <Link to="/#aboutme" style={{ textDecoration: 'none' }} className="styled-link"> <i className="fas fa-caret-right"></i> Qui suis-je ?</Link>
          <br />
          <Link to="/#about" style={{ textDecoration: 'none' }} className="styled-link"> <i className="fas fa-caret-right"></i> Que vais-je apprendre avec ces programmes ?</Link> 
          <br />
          </p>
          {/* Affichage conditionnel du prix */}
          {paymentType && (
            <p>
              <span className="price-text">
              {price}
              <br />
              </span>
              {paymentType === 'subscription' && (
                <span className="access-text">
                  {' '}Accès mensuel au site + groupe en ligne
                </span>
              )}
              {paymentType === 'getCourse' && (
                <span className="access-text">
                  {' '}Accès au cours à vie + groupe en ligne
                </span>
              )}
            </p>
          )}
          <div className="payment-method-selector">
            <label className={`radio-option ${paymentType === 'getCourse' ? 'selected' : ''}`}>
              <input
                type="radio"
                value="getCourse"
                checked={paymentType === 'getCourse'}
                onChange={() => {
                  handlePaymentTypeChange('getCourse');
                  setPaymentStatus('');
                }}
              />
              Obtenir le cours
            </label>
            <label className={`radio-option ${paymentType === 'subscription' ? 'selected' : ''}`}>
              <input
                type="radio"
                value="subscription"
                checked={paymentType === 'subscription'}
                onChange={() => {
                  handlePaymentTypeChange('subscription');
                  setPaymentStatus('');
                }}
              />
              Accès mensuel
            </label>
          </div>
          <p></p>
          
          <div className="payment-details">
            {(paymentType === 'getCourse' || paymentType === 'subscription') && (
              <div className="subscription-summary">
                {/* Contenu spécifique au type de paiement */}
                {paymentType === 'getCourse' && (
                  <>
                    <p><strong>Nom :</strong> {userData?.nom} <strong>Prénom :</strong> {userData?.prenom}</p>
                    <div className="card-details">
                      <div className="input-wrapper">
                        <div className="card-element-container">
                          <CardElement
                            options={cardElementOptions}
                            onChange={(e) => setIsCardComplete(e.complete)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {paymentType === 'subscription' && (
                  <>
                    <p>
                      En cliquant sur "Payer", vous acceptez de souscrire à un abonnement mensuel.
                      Vous pourrez l'annuler à tout moment via la page Mon profil.
                    </p>
                    <p><strong>Nom :</strong> {userData?.nom} <strong>Prénom :</strong> {userData?.prenom}</p>
                    <div className="card-details">
                      <div className="input-wrapper">
                        <div className="card-element-container">
                          <CardElement
                            options={cardElementOptions}
                            onChange={(e) => setIsCardComplete(e.complete)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Section de réduction commune */}
                <div className="discount-section">
                  <p>Code de réduction:</p>
                  <div className="discount-input-group">
                    <input
                      type="text"
                      value={discountCode}
                      onChange={(e) => setDiscountCode(e.target.value.toUpperCase())}
                      placeholder=""
                      className="discount-input"
                    />
                    <button 
                      type="button" 
                      onClick={handleApplyDiscount}
                      className="discount-button"
                      disabled={!discountCode}
                    >
                      Appliquer
                    </button>
                  </div>
                  {discountError && <div className="error-message">{discountError}</div>}
                  {appliedDiscount && (
                    <div className="discount-success">
                      Réduction appliquée : {appliedDiscount}%
                    </div>
                  )}
                </div>
              </div>
            )}

          </div>
          {/* Section des politiques */}
          {paymentType && (
            <div className="terms-section">
              <label className="terms-checkbox">
                <input
                  type="checkbox"
                  checked={acceptedTerms}
                  onChange={(e) => setAcceptedTerms(e.target.checked)}
                />
                J'accepte les{' '}
                <a href="/conditions-generales-de-vente" target="_blank" className="terms-link">
                  Conditions Générales de Vente
                </a>{' '}
                et la{' '}
                <a href="/politique-de-confidentialite" target="_blank" className="terms-link">
                  Politique de Confidentialité
                </a>
              </label>
            </div>
          )}
          <p></p>
          {paymentStatus && <div className="payment-status">{paymentStatus}</div>}
          <button type="submit" disabled={!stripe || isSubmitting || !paymentType} className="payment-submit">
            {isSubmitting ? (
              <>
                <span className="spinner" /> Traitement...
              </>
            ) : (
              'Payer'
            )}
          </button>
        </form>
      </div>
    </>
  );
};

export default CheckoutForm;
