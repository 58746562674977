import React, { useState } from "react";
import questions from "./questions.js"; 
import { useNavigate } from "react-router-dom";
import './style.css';

const QuestionnaireIntro = () => {
  const [currentQuestionId, setCurrentQuestionId] = useState("q1");
  const [answers, setAnswers] = useState({});
  const [textAnswer, setTextAnswer] = useState("");

  const [selectedOption, setSelectedOption] = useState(null); // Stocke l'option choisie
  const [customText, setCustomText] = useState(""); // Stocke la réponse en texte libre

  const navigate = useNavigate();

  // Trouver la question actuelle
  const currentQuestion = questions.find(q => q.id === currentQuestionId);

  // Vérification pour éviter les erreurs
  if (!currentQuestion) {
    return <h2>Chargement...</h2>;
  }

  const handleAnswer = (value) => {
    setSelectedOption(value);
    if (value === "autre") {
      return;
    }

    const updatedAnswers = { ...answers, [currentQuestionId]: value };
    setAnswers(updatedAnswers);

    goToNextQuestion(updatedAnswers);
  };

  const handleTextSubmit = () => {
    if (textAnswer.trim() || customText.trim()) {
      // Stocke la réponse texte (que ce soit une question texte ou "Autre")
      const responseValue = selectedOption === "autre" ? customText : textAnswer;
      const updatedAnswers = { ...answers, [currentQuestionId]: responseValue };
  
      setAnswers(updatedAnswers);
      setTextAnswer(""); 
      setCustomText("");
      setSelectedOption(null); 
  
      goToNextQuestion(updatedAnswers);
    }
  };
  
  const goToNextQuestion = (updatedAnswers) => {
    let nextQuestionId;
    if (currentQuestion.options) {
      // 🔥 Cas d'une question avec des options (choix conditionné ou non)
      nextQuestionId =
        currentQuestion.options.find((option) => option.value === updatedAnswers[currentQuestionId])?.next || currentQuestion.next;
    } else {
      // 🔥 Cas d'une question texte avec un `next` unique
      nextQuestionId = currentQuestion.next;
    }

    if (nextQuestionId) {
      setCurrentQuestionId(nextQuestionId);
    } else {
      navigate("/resultats", { state: { answers: updatedAnswers } });
    }
  };

  // Calcul de la progression
  const totalQuestions = questions.length;
  const currentIndex = questions.findIndex(q => q.id === currentQuestionId) + 1;
  const progressPercentage = (currentIndex / totalQuestions) * 100;

  return (
    <>
      <h1>Quel est ton alignement à toi-même ?</h1>
      <div className="questionnaire">
        {/* Barre de progression */}
        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
        </div>

        {/* Afficher la description si elle existe */}
        {currentQuestion.description && <p className="description">{currentQuestion.description}</p>}

        <h2>{currentQuestion.question}</h2>

        <div className="options">
          {currentQuestion.type === "text" ? (
            <div>
              <textarea
                value={textAnswer}
                onChange={(e) => setTextAnswer(e.target.value)}
                placeholder="Écris ta réponse ici..."
                className="text-input"
              ></textarea>
              <button onClick={handleTextSubmit} className="btn-blue">Valider</button>
            </div>
          ) : (
            currentQuestion.options?.map((option) => (
              <button key={option.value} onClick={() => handleAnswer(option.value)} className="btn-blue">
                {option.text}
              </button>
            ))
          )}
          {/* Afficher un champ texte si "Autre" est sélectionné */}
          {selectedOption === "autre" && (
            <div>
              <textarea
                value={customText}
                onChange={(e) => setCustomText(e.target.value)}
                placeholder="Précise ici..."
                className="text-input"
              ></textarea>
              <button onClick={handleTextSubmit} className="btn-blue">Valider</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QuestionnaireIntro;
