import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
        <p>Cours de développement personnel et plus</p>
        <ul>
          <li><Link to="/mentions-legales">Mentions Légales</Link></li>
          <li><Link to="/conditions-generales-de-vente">CGV</Link></li>
          <li><Link to="/conditions-generales-d-utilisation">CGU</Link></li>
          <li><Link to="/politique-de-confidentialite">Politique de confidentialité</Link></li>
        </ul>
        <p>&copy; 2025 Aurélien Bessonnet. Tous droits réservés.</p>
    </footer>
  );
};

export default Footer;
