import React, { useEffect, Suspense } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import Connexion from './PanelConnexion.js';
import withAuth from './withAuth.js';
import NewsletterPage from './fleur/newsletter.js';
import Verification from './Verification.js';
import ValidationEmail from './Validation_email.js';
import './style.css';
import PrivateRoute from './PrivateRoute.js';

import ArticlePage1 from './pages/article-reseaux-sociaux.js';
import ListeCours from './ListeCours.js';
import Profile from './Profile.js';
import FormationSaimer from './FormationSaimer.js';
import FormationSecouter from './FormationSecouter.js';
import FormationDefi from './FormationDefi.js';
import DisplayWrapper from './DisplayWrapper.js';
import 'react-toastify/dist/ReactToastify.css';

import { useUI } from './UIContext.js';
import { useAuth } from './AuthContext.js';
import HomePage from './HomePage.js';
import QuestionnaireIntro from './components/Questionnaire.js';
import Resultats from './components/Resultats.js';

import CookieBanner from './CookieBanner.js';
import Loading from './Loading.js';
import NotFound from './NotFound.js';

// Chargement paresseux pour les pages statiques
const MentionsLegales = React.lazy(() => import('./MentionsLegales.js'));
const Cgv = React.lazy(() => import('./CGV.js'));
const Cgu = React.lazy(() => import('./CGU.js'));
const PolitiqueDeConfidentialite = React.lazy(() => import('./PolitiqueDeConfidentialite.js'));

// Chargement paresseux pour les chapitres de FormationSaimer
const FormationSaimerChapters = {
  introduction: React.lazy(() => import('./formationSaimer/introduction.js')),
  '1': React.lazy(() => import('./formationSaimer/chapitre1.js')),
  '2': React.lazy(() => import('./formationSaimer/chapitre2.js')),
  '3': React.lazy(() => import('./formationSaimer/chapitre3.js')),
  '4': React.lazy(() => import('./formationSaimer/chapitre4.js')),
  '5': React.lazy(() => import('./formationSaimer/chapitre5.js')),
  '6': React.lazy(() => import('./formationSaimer/chapitre6.js')),
  '7': React.lazy(() => import('./formationSaimer/chapitre7.js')),
  '8': React.lazy(() => import('./formationSaimer/chapitre8.js')),
  '9': React.lazy(() => import('./formationSaimer/chapitre9.js')),
  '10': React.lazy(() => import('./formationSaimer/chapitre10.js')),
  '11': React.lazy(() => import('./formationSaimer/chapitre11.js')),
  '12': React.lazy(() => import('./formationSaimer/chapitre12.js')),
};

// Chargement paresseux pour les chapitres de FormationSecouter
const FormationSecouterChapters = {
  '1': React.lazy(() => import('./formationSecouter/chapitre1.js')),
  '2': React.lazy(() => import('./formationSecouter/chapitre2.js')),
  '3': React.lazy(() => import('./formationSecouter/chapitre3.js')),
  '4': React.lazy(() => import('./formationSecouter/chapitre4.js')),
  '5': React.lazy(() => import('./formationSecouter/chapitre5.js')),
  '6': React.lazy(() => import('./formationSecouter/chapitre6.js')),
  '7': React.lazy(() => import('./formationSecouter/chapitre7.js')),
  '8': React.lazy(() => import('./formationSecouter/chapitre8.js')),
  '9': React.lazy(() => import('./formationSecouter/chapitre9.js')),
  '10': React.lazy(() => import('./formationSecouter/chapitre10.js')),
  '11': React.lazy(() => import('./formationSecouter/chapitre11.js')),
  '12': React.lazy(() => import('./formationSecouter/chapitre12.js')),
};

// Chargement paresseux pour les chapitres de formationDefi
const formationDefiChapters = {
  '1': React.lazy(() => import('./formationDefi/chapitre1.js')),
  '2': React.lazy(() => import('./formationDefi/chapitre2.js')),
  '3': React.lazy(() => import('./formationDefi/chapitre3.js')),
  '4': React.lazy(() => import('./formationDefi/chapitre4.js')),
  '5': React.lazy(() => import('./formationDefi/chapitre5.js')),
};

// URL de l'image par défaut
const DEFAULT_SOCIAL_IMAGE = "/pictures/Flowgraines.png";

const PATHS = {
  FORMATION_S_AIMER: '/formationSaimer',
  FORMATION_S_ECOUTER: '/formationSecouter',
  FORMATION_DEFI: '/formationDefi',
};

// Configuration SEO centralisée
const seoConfig = {
  '/': {
    title: "Flowgraines - plateforme de cours sur l'épanouissement et la connexion intérieure",
    description: "Découvrez des cours uniques pour cultiver votre épanouissement, renforcer votre connexion intérieure et libérer vos émotions.",
    socialImage: DEFAULT_SOCIAL_IMAGE,
  },
  '/cours': {
    title: "Flowgraines - Programmes",
    description: "Explorez nos cours sur l'épanouissement et la connexion intérieure.",
    socialImage: DEFAULT_SOCIAL_IMAGE,
  },
  [PATHS.FORMATION_S_AIMER]: {
    title: "Flowgraines - Cours S'aimer soi-même",
    description: "Rejoignez notre cours S'aimer soi-même ! Développez un réel épanouissement et dévoilez ce qui entrave votre bonheur.",
    socialImage: DEFAULT_SOCIAL_IMAGE,
  },
  [PATHS.FORMATION_S_ECOUTER]: {
    title: "Flowgraines - Cours Écouter la vie en soi",
    description: "Explorez le cours sur l'écoute de soi et reconnectez avec votre intuition.",
    socialImage: DEFAULT_SOCIAL_IMAGE,
  },
  [PATHS.FORMATION_DEFI]: {
    title: "Flowgraines - Cours Entre résilience et renouveau",
    description: "Explorez ce cours pour surmonter vos défis de vie et aborder le renouveau.",
    socialImage: DEFAULT_SOCIAL_IMAGE,
  },
  default: {
    title: "Flowgraines - plateforme de cours sur l'épanouissement et la connexion intérieure",
    description: "Explorez Flowgraines pour cultiver votre épanouissement, renforcer votre connexion intérieure et libérer vos émotions.",
    socialImage: DEFAULT_SOCIAL_IMAGE,
  },
};

// Composant pour gérer les chapitres dynamiques
function ChapitreSaimer() {
  const { chapterId } = useParams();
  const numericId = chapterId.replace('chapitre', '');
  const ChapterComponent = FormationSaimerChapters[numericId] || FormationSaimerChapters['introduction'];
  if (!ChapterComponent) return <div>Chapitre non trouvé</div>;
  const ProtectedChapter = withAuth(ChapterComponent);
  return <ProtectedChapter />;
}

function ChapitreSecouter() {
  const { chapterId } = useParams();
  const numericId = chapterId.replace('chapitre', '');
  const ChapterComponent = FormationSecouterChapters[numericId];
  if (!ChapterComponent) return <div>Chapitre non trouvé</div>;
  const ProtectedChapter = withAuth(ChapterComponent);
  return <ProtectedChapter />;
}

function ChapitreCroissance() {
  const { chapterId } = useParams();
  const numericId = chapterId.replace('chapitre', '');
  const ChapterComponent = formationDefiChapters[numericId];
  if (!ChapterComponent) return <div>Chapitre non trouvé</div>;
  return <ChapterComponent />;
}

// Composant pour gérer les métadonnées
const TitleAndDescription = () => {
  const location = useLocation();
  const { updateTitle, updateMetaDescription, updateSocialMeta } = useAuth();

  const provider = {
    "@type": "Organization",
    "name": "Flowgraines"
  };

  useEffect(() => {
    const path = location.pathname;
    let config;
    if (path === '/') {
      config = seoConfig['/'];
    } else if (path.startsWith(PATHS.FORMATION_S_AIMER)) {
      config = seoConfig[PATHS.FORMATION_S_AIMER];
    } else if (path.startsWith(PATHS.FORMATION_DEFI)) {
      config = seoConfig[PATHS.FORMATION_DEFI];
    } else if (path.startsWith(PATHS.FORMATION_S_ECOUTER)) {
      config = seoConfig[PATHS.FORMATION_S_ECOUTER];
    } else {
      config = seoConfig.default;
    }
    updateTitle(config.title);
    updateMetaDescription(config.description);
    updateSocialMeta(config.title, config.description, `${window.location.origin}${config.socialImage}`, window.location.href);
  }, [updateTitle, updateMetaDescription, updateSocialMeta, location]);

  let structuredData = {};
  const path = location.pathname;

  if (path === '/') {
    structuredData = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": seoConfig['/'].title,
      "description": seoConfig['/'].description,
      "publisher": provider
    };
  } else if (path.startsWith(PATHS.FORMATION_S_AIMER)) {
    structuredData = {
      "@context": "https://schema.org",
      "@type": "Course",
      "name": "S'aimer soi-même",
      "description": seoConfig[PATHS.FORMATION_S_AIMER].description,
      "provider": provider
    };
  } else if (path.startsWith(PATHS.FORMATION_DEFI)) {
    structuredData = {
      "@context": "https://schema.org",
      "@type": "Course",
      "name": "Croissance Personnelle",
      "description": seoConfig[PATHS.FORMATION_DEFI].description,
      "provider": provider
    };
  } else if (path.startsWith(PATHS.FORMATION_S_ECOUTER)) {
    structuredData = {
      "@context": "https://schema.org",
      "@type": "Course",
      "name": "Écouter la vie en soi",
      "description": seoConfig[PATHS.FORMATION_S_ECOUTER].description,
      "provider": provider
    };
  }

  const config = seoConfig[path] || seoConfig.default;
  return (
    <Helmet>
      <link rel="preload" href={config.socialImage} as="image" />
      {Object.keys(structuredData).length > 0 && (
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      )}
    </Helmet>
  );
};

function App() {
  const { currentUser, userData, loadUserData } = useAuth();
  const { isLoginModalOpen } = useUI();

  useEffect(() => {
    if (currentUser && !userData) {
      loadUserData(currentUser.uid).catch(error => {
        console.error("Erreur lors du chargement des données utilisateur", error);
      });
    }
  }, [currentUser, userData, loadUserData]);

  const ProtectedFormationSaimer = withAuth(FormationSaimer);
  const ProtectedFormationSecouter = withAuth(FormationSecouter);
  const ProtectedVerification = withAuth(Verification);
  const ProtectedProfile = withAuth(Profile);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/article-res-sociaux" element={<ArticlePage1 />} />
        <Route path="/*" element={
          <>
            <TitleAndDescription />
            {isLoginModalOpen && <Connexion />}
            <CookieBanner />

            <Suspense fallback={<Loading />}>
              <DisplayWrapper>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/fleur/newsletter" element={<PrivateRoute><NewsletterPage /></PrivateRoute>} />
                  <Route path="/profile" element={<ProtectedProfile />} />
                  <Route path="/cours" element={<ListeCours />} />
                  <Route path="/mentions-legales" element={<MentionsLegales />} />
                  <Route path="/conditions-generales-de-vente" element={<Cgv />} />
                  <Route path="/conditions-generales-d-utilisation" element={<Cgu />} />
                  <Route path="/politique-de-confidentialite" element={<PolitiqueDeConfidentialite />} />
                  <Route path="/validation_email" element={<ValidationEmail />} />
                  <Route path="/verification" element={<ProtectedVerification />} />
                  <Route path="/intro" element={<QuestionnaireIntro />} />
                  <Route path="/resultats" element={<Resultats />} />

                  <Route path={PATHS.FORMATION_S_AIMER} element={<ProtectedFormationSaimer isChapter />} />
                  <Route path={`${PATHS.FORMATION_S_AIMER}/:chapterId`} element={<ChapitreSaimer isChapter />} />

                  <Route path={PATHS.FORMATION_S_ECOUTER} element={<ProtectedFormationSecouter isChapter />} />
                  <Route path={`${PATHS.FORMATION_S_ECOUTER}/:chapterId`} element={<ChapitreSecouter isChapter />} />

                  <Route path={PATHS.FORMATION_DEFI} element={<FormationDefi isChapter />} />
                  <Route path={`${PATHS.FORMATION_DEFI}/:chapterId`} element={<ChapitreCroissance isChapter />} />

                  <Route path="*" element={<NotFound />} />
                </Routes>
              </DisplayWrapper>
            </Suspense>
          </>
        } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;