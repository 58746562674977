import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext.js';
import { ToastContainer } from 'react-toastify';
import Navbar from './Navbar.js';
import Accessibilite from './Accessibilite.js';
import Footer from './Footer.js';
import Loading from './Loading.js';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 100); 

    return () => clearTimeout(timer);
  }, [location]);

  return null;
}

const DisplayWrapper = ({ children }) => {
  const { loading } = useAuth();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 680);

  const [isGlobalLoading, setIsGlobalLoading] = useState(
    location.pathname === '/' || location.pathname === '/cours' // Chargement initial uniquement
  );

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 680);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isGlobalLoading || loading) {
      const timer = setTimeout(() => setIsGlobalLoading(false), 2000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (isGlobalLoading) {
    return <Loading />;
  }

  const routesWithParallax = ['/', '/formationSaimer', '/formationDefi', '/formationSecouter'];
  const hasParallax = routesWithParallax.includes(location.pathname);
  const isHome = location.pathname === '/';
  const isListeCours = location.pathname === '/cours';

  return (
    <div className={`home ${isListeCours ? 'textAdapte' : ''}`}>
      <ToastContainer />
      <Accessibilite />
      <ScrollToTop />
      <Navbar showParallax={hasParallax} isMobile={isMobile} />

      <div
        className={`content ${isHome ? 'home-background' : ''} ${isMobile && !hasParallax ? 'mobile-padding' : 'desktop-padding'}`}
      >
        {children}
      </div>

      <Footer />
    </div>
  );
};

export default DisplayWrapper;
