import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const hour = new Date().getHours();
const isDarkByHour = hour >= 18 || hour < 6;
document.body.setAttribute('data-theme', isDarkByHour ? 'dark' : 'light');

const Accessibilite = () => {
  const [isVisible, setIsVisible] = useState(false);
  const accessibiliteRef = useRef(null);
  const location = useLocation();

  const [isDarkMode, setIsDarkMode] = useState(isDarkByHour);

  const [bgColor, setBgColor] = useState('#6b44aa');

  const routesWithoutAccessibilite = ['/cours', '/mentions-legales', '/conditions-generales-de-vente', '/conditions-generales-d-utilisation',
    '/politique-de-confidentialite', '/validation_email', '/verification', '/mentions-legales'];

  const withAccessibilite = !routesWithoutAccessibilite.includes(location.pathname);
  const isHome = location.pathname === '/';

  const hideTimeoutRef = useRef(null);


  const toggleDarkMode = () => {
    setIsDarkMode(prev => {
      const newMode = !prev;
      document.body.setAttribute('data-theme', newMode ? 'dark' : 'light');
      document.body.style.backgroundColor = bgColor;
      return newMode;
    });
  };

  const changeBgColor = (color) => {
    setBgColor(color);
    const homeElement = document.querySelector('.home');
    const body = document.body;
    if (homeElement) {
      homeElement.style.backgroundColor = color;
    }

    if (color === '#FFFFFF') {
      body.classList.add('fond-blanc');
    } else {
      body.classList.remove('fond-blanc');
    }
    if (color === '#0aa8d3') {
      body.classList.add('fond-cyan');
    } else {
      body.classList.remove('fond-cyan');
    }
  };

  const adjustTextSize = (adjustment) => {
    const html = document.documentElement;
    const currentSize = parseFloat(window.getComputedStyle(html).fontSize);
    html.style.fontSize = adjustment === 'increase' ? `${currentSize * 1.1}px` : `${currentSize * 0.9}px`;
  };

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercent = (scrollPosition / pageHeight) * 100;

      if (!accessibiliteRef.current?.matches(':hover')) {
        setIsVisible(scrollPercent > 7 && scrollPercent < 30);
      }
    };

    if (!isMobile) {
      window.addEventListener('scroll', handleScroll);
    }

    const handleTouchStart = () => {
      const scrollPosition = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercent = (scrollPosition / pageHeight) * 100;

      if (scrollPercent >= 0 && scrollPercent <= 30) {
        setIsVisible(true);
        clearTimeout(hideTimeoutRef.current);
        hideTimeoutRef.current = setTimeout(() => setIsVisible(false), 3000);
      }
    };

    window.addEventListener('touchstart', handleTouchStart);

    return () => {
      if (!isMobile) {
        window.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('touchstart', handleTouchStart);
      clearTimeout(hideTimeoutRef.current);
    };
  }, [bgColor]);

  const handleMouseEnter = () => {
    clearTimeout(hideTimeoutRef.current);
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    hideTimeoutRef.current = setTimeout(() => {
      if (!accessibiliteRef.current?.matches(':hover')) {
        setIsVisible(false);
      }
    }, 300);
  };

  if (!withAccessibilite) return null;

  return (
    <>
      {!isVisible && (
        <div className="accessibility-trigger" onMouseEnter={handleMouseEnter}></div>
      )}

      <div
        ref={accessibiliteRef}
        className={`accessibilite ${isVisible ? 'fadeIn' : 'fadeOut'}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="accessibility-button-container">
          <button
            onClick={toggleDarkMode}
            className={`accessibility-button ${!isDarkMode ? 'dark-mode-button' : 'light-mode-button'}`}
            aria-label="Basculer le mode sombre"
          ></button>

          <div className="color-options">
            <div onClick={() => changeBgColor('#6b44aa')} className="color-square" style={{ backgroundColor: '#6b44aa' }}></div>
            <div onClick={() => changeBgColor('#1d3a70')} className="color-square" style={{ backgroundColor: '#1d3a70' }}></div>
            <div onClick={() => changeBgColor('#FFFFFF')} className="color-square" style={{ backgroundColor: '#FFFFFF' }}></div>
            <div onClick={() => changeBgColor('#0aa8d3')} className="color-square" style={{ backgroundColor: '#0aa8d3' }}></div>
          </div>
        </div>

        {!isHome && (
          <div className="text-size-buttons">
            <button onClick={() => adjustTextSize('increase')} className="accessibility-button text-larger-button" aria-label="Agrandir la taille du texte"></button>
            <button onClick={() => adjustTextSize('decrease')} className="accessibility-button text-smaller-button" aria-label="Réduire la taille du texte"></button>
          </div>
        )}
      </div>
    </>
  );
};

export default Accessibilite;