export const ensembles = [
    {
      id: "Ensemble1",
      title: "Flowgraines : S'aimer soi-même & Écouter la vie en soi",
      subtitle: "Deux programmes centrés sur l'alignement à soi",
      price: 49,
      description: [
        "Ce pack inclut les deux programmes : « S'aimer soi-même » et « Écouter la vie en soi ». Un parcours complet pour une introspection profonde.",
      ],
    }
];
  
export const programmes = [
    { 
        id: "Cours1", 
        title: "S'aimer soi-même", 
        description: [ 
            () => (
                <>
                    Plongez au cœur de vous-même à travers ce programme et découvrez comment <strong>vous aimer plus profondément</strong>. Ouvrez la porte à une vie plus <strong>authentique</strong>, en harmonie avec vos aspirations profondes. Le cours inclut près de 25 pages de contenu avec des exercices et une partie audio pour aller plus loin.
                </>
            ),
            () => (
                <>
                    Un groupe de partage et des contenus supplémentaires hebdomadaires. Ce cours est en pack avec le cours ci-dessous pour approfondir sur l'<strong>écoute de soi</strong>.
                </>
            ),
        ], 
        path: "/formationSaimer", 
    },
    { 
        id: "Cours2", 
        title: "Écouter la vie en soi", 
        description: [ 
            () => (
                <>
                    <strong>Écouter la vie en Soi</strong> est une redécouverte intérieure pour accueillir ce qui se joue vraiment dans notre relation à nous-même. À travers 12 chapitres, ce cours nous invite à <em>dépasser les résistances et auto-sabotages</em> qui nous séparent de notre intuition.
                </>
            ),
            () => (
                <>
                    Le cours inclut une vingtaine de pages de contenu, et des <strong>audios inspirants</strong>.
                </>
            ),
        ], 
        path: "/formationSecouter", 
    },
];

export const courseToBundleMap = {
    "Cours1": "Ensemble1",
    "Cours2": "Ensemble1",
};

export const validDiscounts = {
    'RAYONNEMENT': {
        course: 20, 
        subscription: 15 
    },
    'BEINGME': {
        course: 35,
        subscription: 15
    },
};