import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import StripeCheckout from './StripeCheckout.js';
import { useAuth } from './AuthContext.js';
import RandomBackgroundWrapper from './animations/randomBackgroundWrapper.js';
import { programmes, ensembles, courseToBundleMap } from './metaprogrammes.js';
import { logEvent } from "firebase/analytics";
import { analytics } from './donnees/firebase.js';

const ListeCours = () => {
  const navigate = useNavigate();

  const { currentUser, userData } = useAuth();
  const [idProgrammeItem, setIdProgrammeItem] = useState('');
  const [userConnected, setUserConnected] = useState(null);

  const [showCheckout, setShowCheckout] = useState(false);
  const [selectedProgramme, setSelectedProgramme] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);

  const [message, setMessage] = useState('');
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    setUserConnected(currentUser && userData?.emailVerified);
  }, [currentUser, userData]);

  const handleClick = (path, programmeId) => {
    setIdProgrammeItem(programmeId);
    if (!userConnected) {
      setShowAnimation(true);
      setMessage('Connectez-vous pour accéder à ce programme.');
      setIsFadingOut(false);
      setTimeout(() => {
        setIsFadingOut(true);
        setTimeout(() => {
          setShowAnimation(false);
          setMessage('');
        }, 300);
      }, 2900);
    } else {
      if (userData?.isSubscribed || userData?.coursAchetes?.includes(courseToBundleMap[programmeId])) {
        navigate(path);
      }
    }
  };

  const handlePayment = (programmeId) => {
    const bundleId = courseToBundleMap[programmeId];
    const selectedBundle = ensembles.find(bundle => bundle.id === bundleId);

    if (selectedBundle) {
      logEvent(analytics, "achat_cours", {
        course_id: programmeId,
        bundle_id: bundleId,
        course_name: selectedBundle.title || "Nom non défini",
        price: selectedBundle.price || 0,
        timestamp: new Date().toISOString(),
      });

      setSelectedProgramme(selectedBundle);
      setShowCheckout(true);
    } else {
      console.error("Aucun bundle associé pour ce cours.");
      logEvent(analytics, "error", {
        error_type: "bundle_not_found",
        course_id: programmeId,
        timestamp: new Date().toISOString(),
      });
    }
  };

  const handleClose = () => {
    setShowCheckout(false);
  };

  return ( 
    <RandomBackgroundWrapper>
      <div className='listeCours_p'>
        {currentUser ? (
            <p style={{ color: 'rgb(226, 217, 74)' }}>   
              Bienvenue <span className='name'>{userData?.prenom ? userData.prenom : ''}</span>, découvrez les cours Flowgraines et faites fleurir votre potentiel ! Ils vous guideront pour dépasser vos croyances limitantes, vous ouvrir à l'amour et maîtriser votre monde intérieur. Prêt à faire éclore votre véritable potentiel ?<br />
            </p>
            ) : (
            <p>
              Prêt·e à transformer votre vie ? Plongez dans les flowgraines, des cours animés qui vous guide vers une vie alignée  ! <br />
            </p>
        )}
        
        {currentUser && !userConnected && (
          <p style={{ color: '#4a90e2' }}>Veuillez vérifier votre adresse e-mail ou recharger la page.</p>
        )}
        
        {!currentUser&& <p className="fonce">Accédez à ces cours passionnants en vous connectant !</p>}
        <span><Link to="/intro">Nouveau: prenez part à un quiz d'instrospection pour comprendre ce qui vous met au défi dans votre vie !</Link></span>
      </div>

      <div className="programmes-list">

        {programmes.map((programme) => (
          
          <div key={programme.id} 
          className={`programme-item ${idProgrammeItem === programme.id && showAnimation ? "animate" : ""}`} 
          onClick={() => handleClick(programme.path, programme.id)}>
            <h1>{programme.title}</h1>
            {/* Affichage du message */}
            <div className={`error-wrapper ${isFadingOut ? 'fade-out' : ''}`}>
              {message && (idProgrammeItem === programme.id) && (
                <p className={`error-message ${isFadingOut ? 'fade-out' : ''}`}>
                  {message}
                </p>
              )}
            </div>
            {programme.description.map((renderLine, index) => (
              <p key={index}>{renderLine()}</p>
            ))}
            {userConnected && ( 
            userData?.isSubscribed || userData?.coursAchetes?.includes(courseToBundleMap[programme.id]) ? (
                <div style={{ color: 'rgba(255, 255, 255, 0.9)', backgroundColor: 'rgba(128, 0, 128, 0.1)' }}>
                  Accès au cours
                </div>
              ) : (
                <button className="get-button" onClick={(e) => {
                  e.stopPropagation();
                  handlePayment(programme.id); 
                }}>
                  Obtenir le cours 
                </button>
              )
            )}
          </div>
        ))}

        <div className="programme-item" onClick={() => navigate('/formationDefi')}>
          <h1>Entre résilience et renouveau</h1>
          <p className="dispo">Disponible pour tous !</p>
          <p>
          Évoluer au quotidien est un défi qui demande patience et persévérance. Plutôt que de chercher uniquement à "gagner", apprenons à adopter une attitude de fidélité envers nous-mêmes, faite de résilience et d'ouverture, pour faire face aux épreuves que la vie nous apporte. <br />
          </p>
          <div style={{ color: 'rgba(255, 255, 255, 0.9)', backgroundColor: 'rgba(128, 0, 128, 0.1)' }}>
            Accès au cours
          </div>
        </div>

        <a href="https://www.instagram.com/oretlien_art/" rel="noreferrer noopener" target="_blank">🎨 Suivez-moi sur Instagram !</a>

        <>
        {showCheckout && currentUser ? (
          <StripeCheckout
            onSuccessfulCheckout={() => {
              setShowCheckout(false);
            }}
            programme={selectedProgramme}
            onClose={handleClose}
          />
        ) : (
            <p></p>
        )}
        </> 
      </div>
    </RandomBackgroundWrapper>
  );
};

export default ListeCours;
