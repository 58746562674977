import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

function NotFound() {
  const location = useLocation();
  const attemptedPath = location.pathname.slice(1);

  return (
    <motion.div
      className="not-found"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.6, ease: 'easeOut' }}
    >
      <motion.h1
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        Vous êtes dans le flou sur votre chemin ?
      </motion.h1>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.7 }}
      >
        La branche <strong>{attemptedPath}</strong> n’a pas encore fleuri... Revenez à la base pour explorer !
      </motion.p>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.6, duration: 0.5, type: 'spring', stiffness: 120 }}
      >
        <Link to="/" className="styled-link">
        <i className="fas fa-caret-right fa-xs"></i> Retour à la base 
        </Link>
      </motion.div>
    </motion.div>
  );
}

export default NotFound;