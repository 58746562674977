import React, { useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import TriangleArrowWithIndent from './triangleArrowWithIndent.js';
import { gsap } from "gsap";
import { throttle } from "lodash";
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';

function Parallax() {
  const location = useLocation();

  const backgroundRef1 = useRef(null);
  const backgroundRef2 = useRef(null);
  const imageNaturalWidth = 2475; 
  const imageNaturalHeight = 1653; 

  const goldenStripRef = useRef(null);

  const onScroll = (set) => (e) => {
    const scrollY = window.scrollY;
    const opacity = Math.max(1 - scrollY / 800, 0);
    set({ scrollY, opacity });
  };

  const [{ scrollY, opacity }, set] = useSpring(() => ({
    scrollY: 0,
    opacity: 1,
    config: { tension: 120, friction: 40 },
  }));

  const [textStyle, setTextStyle] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 800 }
  }));

  const [miniTitleStyle, setMiniTitleStyle] = useSpring(() => ({
    opacity: 0,
    scale: 1,
    config: { tension: 200, friction: 30 },
  }));

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const opacity = Math.max(1 - scrollY / 800, 0);
      set({ scrollY, opacity });
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [set]);

  // bande dorée du background 
  useEffect(() => {
    let animationFrameId;
    let lastUpdate = 0;
    const throttleInterval = 20; // Limitation à 50 ms

    const adjustGoldenStripPosition = (timestamp) => {
      // Vérifier si 50 ms se sont écoulées depuis la dernière mise à jour
      if (timestamp - lastUpdate >= throttleInterval) {
        if (backgroundRef1.current && backgroundRef2.current && goldenStripRef.current) {
          const rect1 = backgroundRef1.current.getBoundingClientRect();
          const rect2 = backgroundRef2.current.getBoundingClientRect();
          const parentRect = goldenStripRef.current.parentElement.getBoundingClientRect();

          // Hauteur basée sur la distance entre les bottoms
          const HEIGHT_OFFSET = 10;
          const relativeHeight = rect1.bottom - rect2.bottom + HEIGHT_OFFSET;
          goldenStripRef.current.style.height = `${Math.max(relativeHeight, 0)}px`;

          // Position bottom alignée avec le bas de backgroundRef1
          const bottomOffset = parentRect.bottom - rect1.bottom;
          goldenStripRef.current.style.bottom = `${bottomOffset}px`;
        }
        lastUpdate = timestamp; // Mettre à jour le dernier temps d'exécution
      }
      // Continuer la boucle
      animationFrameId = requestAnimationFrame(adjustGoldenStripPosition);
    };

    // Démarrer la boucle
    animationFrameId = requestAnimationFrame(adjustGoldenStripPosition);

    // Mettre à jour scrollY et opacity lors du scroll
    const scrollHandler = onScroll(set);
    window.addEventListener('scroll', scrollHandler);

    // Nettoyage
    return () => {
      window.removeEventListener('scroll', scrollHandler);
      cancelAnimationFrame(animationFrameId);
    };
  }, [set]);
    
  // Animation horizontale du background
  useEffect(() => {
    const imageAspectRatio = imageNaturalWidth / imageNaturalHeight;

    const updateAnimation = () => {
      if (backgroundRef1.current) {
        // Ajuster containerHeight selon les media queries
        const screenWidth = window.innerWidth;
        let containerHeight = window.innerHeight; // Par défaut 100vh
        if (screenWidth <= 600) {
          containerHeight = window.innerHeight * 0.7; // 70vh
        } else if (screenWidth <= 900) {
          containerHeight = window.innerHeight * 0.75; // 75vh
        } else if (screenWidth <= 1200) {
          containerHeight = window.innerHeight * 0.85; // 85vh
        }

        const displayedImageWidth = Math.ceil(imageAspectRatio * containerHeight);
        
        const visibleCopies = Math.ceil(screenWidth / displayedImageWidth);
        const totalCopies = Math.max(3, visibleCopies + 1);
        const totalWidth = displayedImageWidth * totalCopies;
    
        backgroundRef1.current.style.width = `${totalWidth}px`;
        const scrollPercentage = (1 / totalCopies) * 100;
        backgroundRef1.current.style.setProperty('--scroll-percentage', `${scrollPercentage}%`); 
    
        // Durée basée sur la largeur affichée
        const baseSpeed = 86; 
        const animationDuration = (displayedImageWidth / baseSpeed) * totalCopies;
        backgroundRef1.current.style.animationDuration = `${animationDuration}s`;
      }
    };
  
    updateAnimation();
    const resizeHandler = debounce(updateAnimation, 100);
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
      resizeHandler.cancel?.();
    };
  }, []);

  /* effet de particules */
  useEffect(() => {
    // Vérifie si l'utilisateur préfère réduire les animations
    const prefersReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;
    if (prefersReducedMotion) {
      return;
    }
  
    const particles = document.querySelectorAll(".particle");
    const colors = ['color1', 'color2', 'color3'];
  
    particles.forEach((particle) => {
      const randomColorClass = colors[Math.floor(Math.random() * colors.length)];
      particle.classList.add(randomColorClass);
  
      const initialX = Math.random() * window.innerWidth;
      const initialY = Math.random() * window.innerHeight;
      const initialScale = Math.random() * 0.9 + 0.7;
  
      // Position initiale aléatoire
      gsap.set(particle, {
        x: initialX,
        y: initialY,
        scale: initialScale,
      });
    });
  
    const animateParticle = (particle) => {
      const randomPosition = () => ({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
      });
  
      const newPos = randomPosition();
      const movementDuration = Math.random() * 5 + 10; // Durée du mouvement
      const fadeDuration = Math.random() * 1.5 + 2;      // Durée de la transition d'opacité
      const delay = Math.random() * 2;                   // Décalage initial
      const newScale = Math.random() + 0.3;
  
      // Animation principale
      const timeline = gsap.timeline({
        onComplete: () => animateParticle(particle), // Redémarre le cycle
      });
  
      timeline
        .to(particle, {
          x: newPos.x,
          y: newPos.y,
          scale: newScale,
          opacity: Math.random() * 0.5 + 0.5, // Reste partiellement visible
          duration: movementDuration,
          delay: delay,
          ease: "power1.inOut",
          overwrite: "auto",
        })
        .to(particle, {
          opacity: 0, // Disparition douce
          duration: fadeDuration,
          ease: "power2.out",
          overwrite: "auto",
          onComplete: () => {
            const randomPos = randomPosition();
            const resetScale = Math.random() + 0.3;
            gsap.set(particle, { x: randomPos.x, y: randomPos.y, opacity: 1, scale: resetScale });
          },
        });
    };
  
    particles.forEach((particle) => animateParticle(particle));
  
    const handleMouseMove = throttle((event) => {
      const { clientX: mouseX, clientY: mouseY } = event;
  
      particles.forEach((particle) => {
        const rect = particle.getBoundingClientRect();
        const particleX = rect.left + rect.width / 2;
        const particleY = rect.top + rect.height / 2;
  
        const distance = Math.sqrt(
          Math.pow(mouseX - particleX, 2) + Math.pow(mouseY - particleY, 2)
        );
  
        if (distance < 120) {
          const angle = Math.atan2(particleY - mouseY, particleX - mouseX);
          // Réduire l'offset pour un effet plus subtil
          const offsetX = Math.cos(angle) * (120 - distance) * 0.5;
          const offsetY = Math.sin(angle) * (120 - distance) * 0.5;
  
          // Animation de repoussement avec overwrite pour adoucir la transition
          gsap.to(particle, {
            x: `+=${offsetX}`,
            y: `+=${offsetY}`,
            scale: 1.2,
            duration: 0.6, // Légèrement rallongé pour plus de fluidité
            ease: "power2.out",
            overwrite: "auto",
            onComplete: () => {
              animateParticle(particle);
            },
          });
  
          gsap.to(particle, {
            x: `+=${Math.random() * 30 - 15}`, // Mouvement subtil supplémentaire (amplitude réduite)
            y: `+=${Math.random() * 30 - 15}`,
            delay: 0.2,
            duration: 1.9,
            ease: "power1.inOut",
            overwrite: "auto",
          });
  
          // Retour progressif à la taille normale
          gsap.to(particle, {
            scale: Math.random() + 0.3,
            duration: 1.4,
            ease: "power1.out",
            overwrite: "auto",
          });
        }
      });
    }, 50);
  
    window.addEventListener("mousemove", handleMouseMove);
  
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      gsap.killTweensOf(particles);
    };
  }, []);  

  useEffect(() => {
    const handleScroll = onScroll(set);
    window.addEventListener('scroll', handleScroll);

    /* effet texte */
    const timeoutId = setTimeout(() => {
      setTextStyle.start({
        to: { opacity: 0 },
        config: { duration: 1800 },
        onRest: () => {
          setMiniTitleStyle.start({
            from: {
              opacity: 0,
              scale: 1,
              x: window.innerWidth / 2, 
              y: window.innerHeight / 2,
            },
            to: {
              opacity: 1,
              scale: 1,
              x: window.innerWidth - 150, // Position finale approximative (ajustée par CSS)
              y: 20,
            },
            delay: 200,
            config: { tension: 200, friction: 30, duration: 1000 }, // Animation de 1s
          });
        },
      });
    }, 7000);

    // Lazy loading avec Intersection Observer
    const observerOptions = {
      root: null,
      threshold: 0,
      rootMargin: '200px',
    };

    const updateBackgroundImage = (ref, isDark) => {
      if (ref.current) {
        const lazyImage = ref.current;
        const imageName = lazyImage.dataset.bg;
        const darkModeSuffix = isDark ? 'nuit' : '';
        lazyImage.style.backgroundImage = `url('${imageName.replace(/\.webp$/, `${darkModeSuffix}.webp`)}')`;
      }
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          const imageName = lazyImage.dataset.bg;
          const isDark = document.body.getAttribute('data-theme') === 'dark';
          const darkModeSuffix = isDark ? 'nuit' : '';
    
          // Chargement de l'image haute résolution
          const highResImage = imageName.replace(/\.webp$/, `${darkModeSuffix}.webp`);
          const img = new Image();
          img.src = highResImage;
    
          // Une fois l'image complètement chargée
          img.onload = () => {
            lazyImage.style.backgroundImage = `url('${highResImage}')`;
            lazyImage.classList.add('loaded'); // Ajoute la classe `loaded`
            observer.unobserve(lazyImage); // Arrête l'observation de cette image
          };
    
          // Gestion des erreurs pour éviter un chargement infini en cas de problème
          img.onerror = () => {
            //console.error(`Erreur lors du chargement de l'image : ${highResImage}`);
            observer.unobserve(lazyImage); // On désactive l'observation
          };
        }
      });
    };

    // MutationObserver pour observer les changements de classe sur le body
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    if (backgroundRef1.current) observer.observe(backgroundRef1.current);
    if (backgroundRef2.current) observer.observe(backgroundRef2.current);

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'data-theme') {
          const isDark = document.body.getAttribute('data-theme') === 'dark';
          updateBackgroundImage(backgroundRef1, isDark);
          updateBackgroundImage(backgroundRef2, isDark);
        }
      });
    });
    mutationObserver.observe(document.body, { attributes: true, attributeFilter: ['data-theme'] });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
      mutationObserver.disconnect();
      clearTimeout(timeoutId);
      setTextStyle.stop();
      setMiniTitleStyle.stop();
    };
  }, [set, setTextStyle, setMiniTitleStyle]);

  return (
    <>
      <div className="backgrounds">
        <animated.div
          ref={backgroundRef1}
          className="background-layer1"
          data-bg="/pictures/backgroundLayerBack.webp"
          style={{
            transform: scrollY.to((y) => `translateY(-${y / 3}px)`),
          }}
        />
        <animated.div className="golden-strip" ref={goldenStripRef} />
        <animated.div
          ref={backgroundRef2}
          className="background-layer2"
          data-bg="/pictures/backgroundLayerFront.webp" 
          style={{
            transform: scrollY.to((y) => `translateY(-${y/5}px)`),
            opacity: opacity.to((o) => o),
          }}
        />
        <animated.h1 style={textStyle} className="beginH1">
          <img src="/pictures/logo.png" alt="Logo Flowgraines" className="logo-style" />
          <span className="part1-title">Flow</span>
          <span className="part2-title">graines</span>
        </animated.h1>
        <animated.h2 style={textStyle} className="beginH2">
          Cours en ligne pour s'aimer et s'épanouir !
        </animated.h2>
        <TriangleArrowWithIndent />
        
        {/* effet particules */}
        <animated.div className="particles-container">
          {Array.from({ length: 50 }).map((_, i) => (
            <div key={i} className="particle"></div>
          ))}
        </animated.div>
      </div>
      <animated.h1
        style={{
          opacity: miniTitleStyle.opacity,
          transform: miniTitleStyle.scale.to((s) => `scale(${s})`)
        }}
        className="mini-title"
      >
        <span className="part1-title">Flow</span>
        <span className="part2-title">graines</span>
      </animated.h1>
      {/* Bouton "Voir le contenu" */}
      {location.pathname === '/' && (
      <button
        className="scroll-button"
        onClick={() => {
          document
            .getElementById('about')
            .scrollIntoView({ behavior: 'smooth' });
        }}
      >
        Transformez votre relation avec vous-même
      </button>
    )}
  </>
  );
}

export default Parallax;
