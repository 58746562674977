import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from './donnees/firebase.js';
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, fetchSignInMethodsForEmail } from 'firebase/auth';
import ReCAPTCHA from "react-google-recaptcha";
import './designCo.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useUI } from './UIContext.js';

const Connexion = () => {
    // passage du toggle droite à gauche
    const [isActive, setIsActive] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    // utilisateurs
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [prenom, setPrenom] = useState('');
    const [nom, setNom] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [consent, setConsent] = useState(false);
    const [passwordInputType, setPasswordInputType] = useState("password");

    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageReset, setErrorMessageReset] = useState('');
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    // module recaptcha
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);

    const { isLoginModalOpen, closeLoginModal } = useUI();
    if (!isLoginModalOpen) return null;

    const recaptchaRef = React.createRef();
    const resetPasswordRecaptchaRef = React.createRef();

    const handleOpenResetModal = () => {
        setIsResetModalOpen(true);
    };
    
    const handleCloseResetModal = () => {
        setIsResetModalOpen(false);
    };

    const handleRegisterClick = () => {
        setIsActive(true);
    };

    const handleLoginClick = () => {
        setIsActive(false);
    };

    const clearFields = () => {
        setEmail('');
        setPassword('');
        setNom('');
        setPrenom('');
        setConsent(false);
        setConfirmPassword('');
        setErrorMessage('');
    };

    const togglePasswordVisibility = () => {
        setPasswordInputType(passwordInputType === "password" ? "text" : "password");
      };
      
    const handleConsentChange = (event) => {
        setConsent(event.target.checked);
    };

    const isPasswordValid = (password) => {
        const minLength = 6; 
        if (password.length < minLength) {
            return "Le mot de passe doit contenir au moins " + minLength + " caractères.";
        }
        if (!/[a-z]/.test(password)) {
            return "Le mot de passe doit contenir au moins une lettre minuscule.";
        }
        if (!/[0-9]/.test(password)) {
            return "Le mot de passe doit contenir au moins un chiffre.";
        }
    
        return "";
    };

    const validateFields = (action) => {
        if (!email) {
            setErrorMessage("L'adresse e-mail est nécessaire.");
            return false;
        }

        const isEmailValid = (email) => /\S+@\S+\.\S+/.test(email);
        if (!isEmailValid(email)) {
            setErrorMessage("L'adresse e-mail n'est pas valide.");
            return false;
        }

        if (action === 'register') {
            const passwordError = isPasswordValid(password);
            if (passwordError) {
                setErrorMessage(passwordError);
                return false;
            }

            if (password !== confirmPassword) {
                setErrorMessage("Les mots de passe ne correspondent pas.");
                return false;
            }

            if (!consent) {
                setErrorMessage("Vous devez accepter les Conditions d'Utilisation et la Politique de Confidentialité.");
                console.log(consent)
                return false;
            }
        }

        console.log("Validation des champs réussie");

        setErrorMessage('');
        return true;
    };

    const handleRegister = async (event) => {
        event.preventDefault();
        if (!validateFields('register')) return;

        try {
            setIsLoading(true);
            const recaptchaValue = recaptchaRef.current.getValue();
            if (!recaptchaValue) {
                setErrorMessage("Veuillez valider le reCAPTCHA.");
                return;
            }
        
            const signInMethods = await fetchSignInMethodsForEmail(auth, email);
            if (signInMethods.length > 0) {
                setErrorMessage("Cet email est déjà utilisé.");
                return;
            }

            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await sendEmailVerification(user);
            toast.success(`Email envoyé à ${user.email}`, {
                position: "top-center",
            });

            // Ajoute des informations supplémentaires dans Firestore
            await setDoc(doc(db, "utilisateurs", user.uid), {
                prenom: prenom,
                nom: nom,
                email: email,
                coursAchetes: [],
                emailVerified: false,        
                isSubscribed: false,
                creationTime: Date.now()
            });
            clearFields();
            navigate('/');
            
            closeLoginModal();
        } catch (error) {
            console.error("Erreur lors de l'inscription :", error.message);
            toast.error("Une erreur est survenue lors de la création du compte. Veuillez réessayer.", {
                position: "top-center",
            });
        } finally {
            setIsLoading(false);
            recaptchaRef.current.reset();
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        if (!validateFields()) return;
      
        if (!password) {
          setErrorMessage("Veuillez fournir votre mot de passe.");
          return false;
        }
      
        try {
            await signInWithEmailAndPassword(auth, email, password);
            clearFields();
            closeLoginModal();   
        } catch (error) {
            setErrorMessage("Erreur lors de l'authentification.");
        }
    };

    const handleResetPassword = async () => {
        if (!email) {
            setErrorMessageReset("L'adresse e-mail est nécessaire.");
            return false;
        }

        const recaptchaValue = resetPasswordRecaptchaRef.current.getValue();
        if (!recaptchaValue) {
            setErrorMessageReset("Veuillez valider le recaptcha");
            return false;
        }
        
        // Vérifier si l'email existe dans la base de données Firestore
        const querySnapshot = await getDocs(query(collection(db, "utilisateurs"), where("email", "==", email)));
        
        setErrorMessageReset("Un mail vous sera transmis si ce compte existe.");
        
        if (!querySnapshot.empty) {
            try {
                await sendPasswordResetEmail(auth, email);
                toast.success("Un email pour réinitialiser votre mot de passe vous a été envoyé.", {
                    position: "top-center",
                });
                resetPasswordRecaptchaRef.current?.reset();
                handleCloseResetModal();
            } catch (error) {
                console.error("Erreur lors de la réinitialisation du mot de passe :", error);
            }
        }
    }; 
    
    return (
        <>
            <div className="overlay" onClick={closeLoginModal}></div>
            <div className={`container ${isActive ? 'active' : ''}`}  onClick={e => e.stopPropagation()}>
                <div className={`form-container sign-up ${!isVisible ? 'inactive' : ''}`}>
                <div className="scrollable-content">
                    <form>
                        <h1>Créer un compte</h1>
                        <p style={{ color: "#5c6bc0" }}>Renseignez vos informations pour vos connexions </p>
                        <label htmlFor="prenom">Prénom</label>
                        <input id="prenom" type="text" placeholder="Prénom" value={prenom} onChange={(e) => setPrenom(e.target.value)} required />

                        <label htmlFor="nom">Nom</label>
                        <input id="nom" type="text" placeholder="Nom" value={nom} onChange={(e) => setNom(e.target.value)}  required />

                        <label htmlFor="email">Email</label>
                        <input id="email" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}  required />

                        <label htmlFor="password">Mot de passe</label>
                        <div className='password-container'>
                            <input id="password" type={passwordInputType} placeholder="Mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} required />

                            <div role="button" 
                                onClick={togglePasswordVisibility}
                                className="button-toggle-visibility">
                                <img src={passwordInputType === "password" ? "/pictures/oeil_afficher.png" : "/pictures/oeil_cacher.png"} alt="Toggle visibility" />
                            </div>
                        </div>

                        <label htmlFor="confirmPassword">Confirmez le mot de passe</label>
                        <input id="confirmPassword" type="password" placeholder="Confirmez le mot de passe" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />

                        <div className="checkbox-container">
                            <input type="checkbox" id="consent" onChange={handleConsentChange}  required />
                            <label for="consent">J'accepte les <a href="https://flowgraines.fr/conditions-generales-d-utilisation" target='_blank' rel="noopener noreferrer">Conditions d'Utilisation</a> et la <a href="https://flowgraines.fr/mentions-legales" target='_blank' rel="noopener noreferrer">Politique de Confidentialité.</a></label>
                        </div>

                        <div className="recaptcha-container">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        />
                        </div>
                        {/* Affiche les messages d'erreur */}
                        {errorMessage && <div className="error-message">{errorMessage}</div>}

                        <button onClick={handleRegister} >
                            {isLoading ? "Inscription en cours..." : "S'inscrire"}
                        </button>


                        <button className="hidden" onClick={handleRegisterClick}>S'inscrire</button>
                    </form>
                </div>
                </div>
                <div className={`form-container sign-in ${isVisible ? 'inactive' : ''}`}>
                    <form>
                        <h1>Se connecter</h1>
                        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        <div className='password-container'>
                            <input type={passwordInputType} placeholder="Mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <div role="button" 
                            onClick={togglePasswordVisibility}
                            className="button-toggle-visibility">
                            <img src={passwordInputType === "password" ? "/pictures/oeil_afficher.png" : "/pictures/oeil_cacher.png"} alt="Toggle visibility" />
                            </div>
                        </div>
                        {/* Affiche les messages d'erreur */}
                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                        <button onClick={handleLogin}>Se connecter</button>
                        <button type="button"
                        onClick={handleOpenResetModal}
                        style={{
                            background: 'none', color: 'blue', border: 'none', padding: '0',
                            font: 'inherit', textTransform: 'none', cursor: 'pointer', textDecoration: 'none'
                        }}> 
                        Mot de passe oublié ?
                        </button>
                        <button className="hidden" onClick={handleLoginClick}>Se connecter</button>
                    </form>
                </div>
                {isResetModalOpen && (
                    <div className="reset-modal-backdrop">
                        <div className="reset-modal-content">
                            <p>Entrez votre mail pour réinitialiser votre mot de passe.</p>
                            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            {errorMessageReset && <div className="error-message">{errorMessageReset}</div>}
                            <p></p>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                ref={resetPasswordRecaptchaRef}
                            />
                            <button onClick={handleResetPassword}>Réinitialiser le mot de passe</button><br />
                            <button onClick={handleCloseResetModal}>Fermer</button>
                        </div>
                    </div>
                )}
                <div className="toggle-container">
                    <div className="toggle">
                        <div className="toggle-panel toggle-left">
                            <h1>Bienvenue de nouveau !</h1>
                            <p>Entrez vos détails personnels pour utiliser les fonctionnalités du site</p>
                            <button className="hidden" onClick={() => { handleLoginClick(); setIsVisible(false);}}>Se connecter</button>
                        </div>
                        <div className="toggle-panel toggle-right">
                            <h1>Bonjour, ami(e) !</h1>
                            <p>Future inscription pour l'accès aux différents cours.</p>
                            <button className="hidden" onClick={() => {handleRegisterClick(); setIsVisible(true);}}>S'inscrire</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Connexion;
