export const pathAliasMap = {
    cours: "Programmes",
    formationSaimer: "Cours S'aimer soi-même",
    formationDefi: "Cours Entre résilience et renouveau",
    formationSecouter: "Cours Écouter la vie en soi",
    profile: "Mon Profil",
    chapitre1: "Chapitre 1",
    chapitre2: "Chapitre 2",
    chapitre3: "Chapitre 3",
    chapitre4: "Chapitre 4",
    chapitre5: "Chapitre 5",
    chapitre6: "Chapitre 6",
    chapitre7: "Chapitre 7",
    chapitre8: "Chapitre 8",
    chapitre9: "Chapitre 9",
    chapitre10: "Chapitre 10",
    chapitre11: "Chapitre 11",
    chapitre12: "Chapitre 12",
    introduction: "Introduction",
    "mentions-legales": "Mentions légales",
    "conditions-generales-de-vente": "Conditions générales de vente",
    "conditions-generales-d-utilisation": "Conditions générales d'utilisation",
    "politique-de-confidentialite": "Politique de confidentialité",
    intro: "Instrospection",
    resultats: "Résultats du questionnaire",
  };